import React, { useState } from 'react'
import {  Spin  } from 'antd'
import style from './Portfolio.module.scss'

export default function Portfolio({font}) {
  const [ showSpin, setShowSpin ] = useState(false)
  return (
    <div className={style.wrapPortfolio}>
        <div className={style.portfolio}>
            <div className={style.wrapImg}>
                <div className={style.blocImg}>
                    <div className={style.block}>
                        <a href="https://andreevskiy.by">
                            <img className={style.img} src={require('./images/home.jpg')} rel= "prefetch" title='andreevskiy' alt='andreevskiy' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>ЖК "Андреевский"</span>
                    </div>
                    <div className={style.block}>
                        <a href="https://www.gosuslugi.ru/">
                            <img className={style.img} src={require('./images/gosuslugi1.webp')} rel= "prefetch" title='gosuslugi' alt='gosuslugi'  width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>ГосУслуги</span>
                    </div>
                    <div className={style.block}>
                        <a href="https://myexport.exportcenter.ru/">
                            <img className={style.img} src={require('./images/export1.webp')} rel= "prefetch" title='exportcenter' alt='exportcenter' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Мой Экспорт</span>
                    </div>
                </div>

                <div className={style.blocImg}>
                    <div className={style.block}>
                        <a href="https://petrikov-auto.by/">
                            <img className={style.img} src={require('./images/bus1.png')} rel= "prefetch" title='petrikov-auto' alt='petrikov-auto' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Пассажирские перевозки</span>
                    </div>
                    <div className={style.block}>
                        <a href="https://cookies.games/">
                            <img className={style.img} src={require('./images/cookies.games.png')} rel= "prefetch" title='plants' alt='plants' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Сookies.Games</span>
                    </div>
                    <div className={style.block}>
                        <a href="https://yummykemer.com/">
                            <img className={style.img} src={require('./images/sushiLogo.png')} rel= "prefetch" title='plants' alt='plants' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Yummy Kemer menu</span>
                    </div>
                </div>

                <div className={style.blocImg}>
                    <div className={style.block}>
                        <a href="https://apps.apple.com/us/app/ai-plant-identifier-app-plnt/id1560965917?l=en">
                            <img className={style.img} src={require('./images/plants1.webp')} rel= "prefetch" title='plants' alt='plants' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Plants App</span>
                    </div>
                    {/* <div className={style.block}>
                        <a href="https://apps.apple.com/app/capsigo">
                            <img className={style.img} src={require('./images/e-sim.jpg')} rel= "prefetch" title='esim' alt='esim' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>eSIM App</span>
                    </div> */}
                    <div className={style.block}>
                        <a href="https://ada.org.au/">
                            <img className={style.img} src={require('./images/ada.webp')} rel= "prefetch" title='dentist' alt='dentist' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Dentist tools</span>
                    </div>
                    <div className={style.block}>
                        <a href="https://play.google.com/store/apps/details?id=com.pavel.filonchyk.bookingtickets" target="_blank" rel="external">
                            <img className={style.img} src={require('./images/bus2.png')} rel= "prefetch" title='esim' alt='esim' width='287' height='162' draggable="false"/>
                        </a>
                        <span className={style.text} style={{fontFamily: font ? 'Lobster' : ''}}>Бронь билетов</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
